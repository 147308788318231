import React, { useContext } from "react"
import clsx from "clsx"
import { makeComponentStyles } from "../components/mixins"
import Container from "@components/Container/Container.jsx"
import MainWrap from "@components/Wrap/MainWrap"
import IconButton from "@components/Buttons/IconButton"
import { Box, Typography, makeStyles } from "@material-ui/core"
import Layout from "../components/Layout/Layout"
import NoProfile from "@static/images/NoProfile.svg"
import VK from "@static/images/social/VK.svg"
import IG from "@static/images/social/Instagram.svg"
import FB from "@static/images/social/FaceBook.svg"
import YT from "@static/images/social/YouTube.svg"
import TW from "@static/images/social/Twitter.svg"
import TG from "@static/images/social/Telegram.svg"
import LI from "@static/images/social/LinkedIn.svg"
import GP from "@static/images/social/GooglePlus.svg"
import Grid from "@material-ui/core/Grid"
import { graphql } from "gatsby";
import Markdown from 'markdown-to-jsx';
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../heplers.js"
import FloatWrap from "@components/Wrap/FloatWrap.jsx";
import Seo from "@components/seo"

const styles = ({ breakpoints }) => ({
  // <<<<<<<<<<<<<<<< Блок "Футер"  >>>>>>>>>>>>>>>>>>>>>
  volunteer__footer_header: {
    // fontSize: "20px",
    lineHeight: "24px",
    paddingBottom: "26px",
    paddingTop: "120px",
    [breakpoints.down("md")]: {
      paddingTop: "40px",
    },
  },
  volunteer__footer_container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  volunteer__footer_social: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "70px",
    [breakpoints.down("md")]: {
      paddingBottom: "50px",
    },
  },
  volunteer__icon_box: {
    display: "flex",
  },
  volunteer__icon: {
    marginRight: "27px",
    [breakpoints.down("sm")]: {
      marginRight: "20px",
    },
  },
  volunteer__footer_workers: {
    display: "flex",
    justifyContent: "space-around",
    paddingBottom: "50px",
  },
  volunteer__worker__box_icon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    margin: '10px 0'
  },
  volunteer__worker__btn_icon: {
    width: "134px",
    height: "134px",
    "&::before": {
      content: '""',
      position: "absolute",
      zIndex: 1,
      borderRadius: "50%",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
    },
  },
  volunteer__worker__name: {
    paddingTop: "10px",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
  },
  volunteer__worker__speciality: {
    width: "320px",
    // height: "95px",
    paddingTop: "10px",
    marginBottom: "20px",
    [breakpoints.down("sm")]: {
      width: "150px",
      // height: "145px"
    },
  },
  // <<<<<<<<<<<<<<<< Общие классы  >>>>>>>>>>>>>>>>>>>>>
  desktop_text: {
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
  },
  mobile_text: {
    [breakpoints.up("sm")]: {
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "17px"
    }
  },
  bold_weight: {
    fontWeight: "500",
  }
});

function getLogo(socNet) {
  switch (socNet) {
    case 'Vkontakte':
      return VK;
    case 'FaceBook':
      return FB;
    case 'Instagram':
      return IG;
    case 'Telegram':
      return TG;
    case 'Twitter':
      return TW;
    case 'LinkedIn':
      return LI;
    case 'YouTube':
      return YT;
    case 'GooglePlus':
      return GP;
  }
}

function go(link) {
  window.open(link, '_blank');
}

const makeImage = (url) => makeStyles({
  root: {
    "&::before": {
      background: `url(${url ? url : NoProfile}) center no-repeat`,
    },
  }
})().root;

const Volunteers = ({ data }) => {
  const {
    // <<<<<<<<<<<<<<<< Блок "Футер"  >>>>>>>>>>>>>>>>>>>>>
    volunteer__footer_header,
    volunteer__footer_container,
    volunteer__footer_social,
    volunteer__icon_box,
    volunteer__icon,
    volunteer__footer_workers,
    volunteer__worker__box_icon,
    volunteer__worker__btn_icon,
    volunteer__worker__name,
    volunteer__worker__speciality,
    // <<<<<<<<<<<<<<<< Общие классы  >>>>>>>>>>>>>>>>>>>>>
    desktop_text,
    mobile_text,
    bold_weight,
  } = makeComponentStyles(styles);

  const { language } = useContext(IntlContextConsumer);
  const intl = useIntl();
  const {
    Info,
    InfoImage,
    SocialNetTitle,
    SocialGroups,
    workers,
    VolunteersSEO,
  } = getStrapiContentByLang(data.allStrapiVolunteers.edges, language);

  return (
    <div>
      <Seo title={VolunteersSEO?.TitleSEO}
           description={VolunteersSEO?.DescriptionSEO}
           keywords={VolunteersSEO?.KeywordsSEO.split(',')}
      />

      <>
        <Layout>
          {/*<<<<<<<<<<<<<<<< Блок "Волонтеры"  >>>>>>>>>>>>>>>>>>>>>*/}
          <Container mw={"lg"}>
            <MainWrap>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"} style={{ color: "#000" }}>
                  {intl.formatMessage({ id: 'volunteers' })}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body>
                <FloatWrap Img={InfoImage.localFile.publicURL} Float='right'>
                  <Markdown>{Info}</Markdown>
                </FloatWrap>
              </MainWrap.Body>
            </MainWrap>
          </Container>

          {/*<<<<<<<<<<<<<<<< Блок "Футер"  >>>>>>>>>>>>>>>>>>>>>*/}
          <Container mw={"lg"}>
            <Box className={volunteer__footer_container}>
              <div className={volunteer__footer_social}>
                <Typography className={clsx(volunteer__footer_header, bold_weight)} style={{ color: "#000" }}>
                  {SocialNetTitle}
                </Typography>
                <div className={volunteer__icon_box}>
                  {
                    SocialGroups.map(({ SocialNetName, Url }) => (
                      <IconButton className={volunteer__icon} onClick={() => go(Url)}><img src={getLogo(SocialNetName)} alt={SocialNetName} /></IconButton>
                    ))
                  }
                </div>
              </div>
              <div className={volunteer__footer_workers}>
                <Grid container>
                  {
                    workers.map(({ ProfileImage, Name, Surname, Work, Phone, SocialLinks }) => (
                      <Grid item xs={6} md={4}>
                        <div className={volunteer__worker__box_icon}>
                          <IconButton color="primary" className={clsx(volunteer__worker__btn_icon, makeImage(
                            ProfileImage ? ProfileImage.localFile.publicURL : null
                          ))}></IconButton>
                          <Typography className={volunteer__worker__name}>{Name} {Surname}</Typography>
                          <Typography className={clsx(volunteer__worker__speciality)}>{Work}<br />{Phone}</Typography>
                          <div className={volunteer__icon_box}>
                            {
                              SocialLinks.map(({ SocialNetName, Url }) => (
                                <IconButton style={{ margin: '0 15px' }} onClick={() => go(Url)}><img src={getLogo(SocialNetName)} alt={SocialNetName} /></IconButton>
                              ))
                            }
                          </div>
                        </div>
                      </Grid>
                    ))
                  }
                </Grid>
              </div>
            </Box>
          </Container>
        </Layout>
      </>
    </div>
  )
}

export default Volunteers
export const pageQuery = graphql`{
  allStrapiVolunteers {
    edges {
      node {
        locale
        Info
        InfoImage {
          localFile {
            publicURL
          }
        }
        SocialNetTitle
        SocialGroups {
          SocialNetName
          Url
        }
        workers {
          ProfileImage {
            localFile {
              publicURL
            }
          }
          Name
          Surname
          Work
          Phone
          SocialLinks {
            SocialNetName
            Url
          }
        }
        VolunteersSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        } 
      }
    }
  }
}`;
